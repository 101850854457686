body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  background-color: #F8F6F8;
}

ul,div,body {
  scrollbar-width: thin;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a { 
  color: inherit; 
  text-decoration: none;
} 

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar
{
    width: 5px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    background-color: gray;
    
}

.grecaptcha-badge { 
  visibility: hidden;
}